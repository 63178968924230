<template>
  <Layout class="ReleaseProduct">
    <div class="content">
      <h4 class="title">
        商品类型
      </h4>
      <div class="type-wrapper">
        <el-card
          shadow="hover"
          class="card"
          :class="{
            active: formData.goodsType === 1,
            disabled: isUpdatePage
          }"
          @click.native="handleChangeActiveType(1)"
        >
          <span class="name">实物商品</span>
          <div class="des">
            物流发货
          </div>
        </el-card>
        <el-card
          shadow="hover"
          class="card"
          :class="{
            active: formData.goodsType === 2,
            disabled: isUpdatePage
          }"
          @click.native="handleChangeActiveType(2)"
        >
          <span class="name">CDK商品</span>
          <div class="des">
            无需物流
          </div>
        </el-card>
      </div>
      <el-form
        ref="form"
        v-loading="loading"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="100px"
        label-position="left"
      >
        <h4 class="title">
          基本信息
        </h4>
        <div class="baseInfoWrapper">
          <el-form-item
            label="商品名"
            prop="goodsName"
          >
            <el-input
              v-model="formData.goodsName"
              clearable
              placeholder="在此输入商品描述"
            />
          </el-form-item>
          <el-form-item
            label="主图视频"
          >
            <UploadImage
              :file-list.sync="masterVideoList"
            />
          </el-form-item>
          <el-form-item
            label="商品轮播图"
            prop="carousel"
          >
            <UploadImage
              :limit="6"
              :file-list.sync="carouselList"
            />
          </el-form-item>
          <el-form-item
            label="封面图横"
            prop="imageHorizontal"
          >
            <UploadImage
              :file-list.sync="imageHorizontalList"
            />
          </el-form-item>
          <el-form-item
            label="封面图竖"
            prop="imageVertical"
          >
            <UploadImage
              :vertical="true"
              :file-list.sync="imageVerticalList"
            />
          </el-form-item>
          <el-form-item
            label="描述"
            prop="goodsDesc"
          >
            <el-input
              v-model="formData.goodsDesc"
              type="textarea"
              clearable
              :autosize="{minRows: 3}"
            />
          </el-form-item>
          <el-form-item
            v-if="formData.goodsType === 2"
            label="CDK"
            prop="goodsCdk"
          >
            <el-input
              :value="formData.goodsCdk"
              type="textarea"
              clearable
              readonly
              :autosize="{minRows: 5}"
            />
            <div class="goodsCdkCtrl">
              <el-button
                v-if="isUpdatePage"
                :loading="downloadLoading"
                type="text"
                @click="handleDownload"
              >
                下载已出售CDK
              </el-button>
              <uploadExcel
                style="margin-left: 10px;"
                text="填充新CDK"
                button="text"
                @uploaded="handleUploadExcel"
              />
            </div>
            <template #error="{error}">
              <!-- eslint-disable vue/no-v-html-->
              <div>
                <el-button
                  v-if="error.includes('具有重复项')"
                  type="text"
                  @click="handleRevoke"
                >
                  撤销本次填充
                </el-button>
                <div
                  class="custom-error"
                  v-html="error"
                />
              </div>
            </template>
          </el-form-item>
        </div>
        <h4 class="title">
          价格信息 <sub>(最多2位小数)</sub>
        </h4>
        <div class="priceWrapper">
          <el-form-item
            label="原价"
            prop="goodsPrice"
          >
            <el-input
              v-model="formData.goodsPrice"
              clearable
            >
              <template slot="prepend">
                <i class="fa fa-dollar" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="formData.goodsType === 1"
            label="运费"
            prop="shippingPrice"
          >
            <el-input
              v-model="formData.shippingPrice"
              clearable
            >
              <template slot="prepend">
                <i class="fa fa-dollar" />
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  releaseMallGoods,
  getMallGoodsDetail,
  updateMallGoods,
  downloadSaleCdk
} from '@/api/mall-server'
import uploadExcel from '@/components/UploadExcel/uploadExcel'
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import { cloneDeep } from 'lodash'
export default {
  name: 'ReleaseProduct',
  components: { uploadExcel },
  data () {
    const numberCheck = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('此项必须为数字'))
      } else {
        callback()
      }
    }
    const RANGE_CHECK = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error('此项需大于0'))
      } else {
        callback()
      }
    }
    const carouselCheck = (rule, value, callback) => {
      if (this.carouselList.length) {
        return callback()
      } else {
        callback(new Error('轮播图必填'))
      }
    }
    const imageHorizontalCheck = (rule, value, callback) => {
      if (this.imageHorizontalList.length) {
        return callback()
      } else {
        callback(new Error('封面图横必填'))
      }
    }
    const imageVerticalCheck = (rule, value, callback) => {
      if (this.imageVerticalList.length) {
        return callback()
      } else {
        callback(new Error('封面图竖必填'))
      }
    }
    const REPEAT_CHECK = (rule, value, callback) => {
      const arr = value.split('\n')
      if (arr.length) {
        const repeatObj = {}
        const repeatArr = []
        for (let i = 0; i < arr.length; i++) {
          if (!repeatObj[arr[i]]) {
            repeatObj[arr[i]] = true
          } else {
            repeatArr.push(arr[i])
          }
        }
        if (!repeatArr.length) {
          callback()
        } else {
          callback(new Error(
            '具有重复项:<br>' + repeatArr.join('<br>')
          ))
        }
      } else {
        callback()
      }
    }
    const DECIMAL_CHECK = (rule, value, callback) => {
      const decimalLength = (value.toString().split('.')[1] || '').length || 0
      if (decimalLength > 2) {
        callback(new Error('小数位数不能多于2位'))
      } else {
        callback()
      }
    }
    return {
      isUpdatePage: false,
      masterVideoList: [],
      carouselList: [],
      imageHorizontalList: [],
      imageVerticalList: [],
      loading: false,
      goodsCdkHistory: [],
      formData: {
        goodsType: 1,
        goodsCdk: ''
      },
      rules: {
        goodsName: [{ required: true, message: '商品名必填' }],
        carousel: [{ required: true, validator: carouselCheck }],
        imageHorizontal: [{ required: true, validator: imageHorizontalCheck }],
        imageVertical: [{ required: true, validator: imageVerticalCheck }],
        goodsDesc: [{ required: true, message: '描述必填' }],
        goodsCdk: [
          { required: true, message: 'CDK必填' },
          { validator: REPEAT_CHECK }
        ],
        goodsPrice: [
          { required: true, message: '原价必填' },
          { validator: numberCheck },
          { validator: RANGE_CHECK },
          { validator: DECIMAL_CHECK }
        ],
        shippingPrice: [
          { required: true, message: '运费必填' },
          { validator: numberCheck },
          { validator: RANGE_CHECK },
          { validator: DECIMAL_CHECK }
        ]
      },
      updateLoading: false,
      batchUploadTemplate: {
        list: [],
        header: ['订单时间', '订单号', 'CDKey', 'Uid', '商品名', '价格'],
        fields: ['orderTime', 'orderId', 'cdkInfo', 'userIdStr', 'goodsName', 'goodsPrice']
      },
      downloadLoading: false
    }
  },
  created () {
    this.isUpdatePage = this.$route.name === 'EditProduct'
    if (this.isUpdatePage) {
      this.getMallGoodsDetail()
    }
  },
  methods: {
    getMallGoodsDetail () {
      this.loading = true
      getMallGoodsDetail({
        id: this.$route.query.id
      })
        .then(res => {
          if (res.code === 200) {
            this.formData = this.formatResult(res.data)
            this.goodsCdkHistory.push(this.formData.goodsCdk)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleRevoke () {
      this.goodsCdkHistory.pop()
      this.formData.goodsCdk = this.goodsCdkHistory[this.goodsCdkHistory.length - 1]
    },
    handleChangeActiveType (type) {
      if (this.isUpdatePage) return false
      if (this.formData.goodsType === type) return false
      this.formData.goodsType = type
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, '已出售CDK_ID_' + this.formData.id + '_' + new Date().toLocaleDateString())
    },
    handleSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.updateLoading = true
          const params = this.formatParams(this.formData)
          if (!this.isUpdatePage) {
            releaseMallGoods(params)
              .then(res => {
                if (res.code === 200) {
                  this.$message.success('发布成功')
                  this.$refs.form.resetFields()
                }
              })
              .finally(() => {
                this.updateLoading = false
              })
          } else {
            updateMallGoods(params)
              .then(res => {
                if (res.code === 200) {
                  this.$message.success('更新成功')
                  this.$router.back()
                }
              })
              .finally(() => {
                this.updateLoading = false
              })
          }
        }
      })
    },
    handleDownload () {
      this.downloadLoading = true
      downloadSaleCdk({ id: this.formData.id })
        .then(res => {
          if (res.code === 200) {
            if (res.data.list) {
              this.batchUploadTemplate.list = res.data.list
              this.downloadExcel()
            } else {
              this.$message.warning('暂无数据')
            }
          }
        })
        .finally(() => {
          this.downloadLoading = false
        })
    },
    handleUploadExcel (data) {
      const list = data.list.map(item => {
        return item.CDK
      })
      const goodsCdk = this.formData.goodsCdk
      this.formData.goodsCdk = goodsCdk ? goodsCdk + '\n' + list.join('\n') : list.join('\n')
      this.goodsCdkHistory.push(this.formData.goodsCdk)
    },
    formatParams (data) {
      const params = cloneDeep(data)
      params.masterVideo = this.masterVideoList.map(item => item.url).join()
      params.carousel = this.carouselList.map(item => item.url)
      params.imageHorizontal = this.imageHorizontalList.map(item => item.url).join()
      params.imageVertical = this.imageVerticalList.map(item => item.url).join()
      return params
    },
    formatResult (data) {
      if (data.masterVideo) this.masterVideoList.push({ url: data.masterVideo })
      if (data.imageHorizontal) this.imageHorizontalList.push({ url: data.imageHorizontal })
      if (data.imageVertical) this.imageVerticalList.push({ url: data.imageVertical })
      if (data.carousel) {
        this.carouselList = data.carousel.map((item) => {
          return { url: item }
        })
      }
      return data
    }
  }
}
</script>

<style lang="less">
  .ReleaseProduct{
    .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
      box-shadow: 0 2px 12px 0 rgba(130,41,229, 0.1);
    }
  }
</style>
<style scoped lang="less">
  .ReleaseProduct{
    .content{
      margin: 50px 80px;
      .type-wrapper{
        display: flex;
        .card{
          width: 150px;
          height: 80px;
          margin-right: 20px;
          cursor: pointer;
          .des{
            margin-top: 10px;
            font-size: 12px;
          }
          &.active{
            border-color: @primary;
            span.name{
              color: @primary ;
            }
          }
          &.disabled{
            background-color: #f5f5f5;
            cursor: not-allowed;
          }
        }
      }

      .el-input,
      .el-textarea {
        width: 420px;
      }
      .goodsCdkCtrl{
        display: flex;
        justify-content: flex-end;
        width: 420px;
      }
      .custom-error{
        color: #F56C6C;
        font-size: 12px;
        line-height: 20px;
      }
    }

    .ctrl-area{
      margin-top: 80px;
      .el-button{
        padding:10px  40px;
      }
    }
  }
</style>
