import request from '@/utils/request'

export function releaseMallGoods (data) {
  return request({
    url: '/pay/mall/releaseMallGoods',
    data
  })
}

export function getMallGoodsList (data) {
  return request({
    url: '/pay/mall/getMallGoodsList',
    data
  })
}

export function updateMallGoodsStatus (data) {
  return request({
    url: '/pay/mall/updateMallGoodsStatus',
    data
  })
}

export function getMallGoodsDetail (data) {
  return request({
    url: '/pay/mall/getMallGoodsDetail',
    data
  })
}

export function updateMallGoods (data) {
  return request({
    url: '/pay/mall/updateMallGoods',
    data
  })
}

export function updateGoodsRecommend (data) {
  return request({
    url: '/pay/mall/updateGoodsRecommend',
    data
  })
}

export function downloadSaleCdk (data) {
  return request({
    url: '/pay/mall/downloadSaleCdk',
    data
  })
}

export function getMallOrderList (data) {
  return request({
    url: '/pay/mall/getMallOrderList',
    data
  })
}

export function updateMallOrderStatus (data) {
  return request({
    url: '/pay/mall/updateMallOrderStatus',
    data
  })
}
